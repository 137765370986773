import {
  Box,
  debounce,
  Grid,
  Input,
  makeStyles,
  Select,
  styled,
} from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { default as pAPI } from '../../api/partner';
import { AppWhiteButton } from '../../components/core/buttons';
import { useAppSelector } from '../../hooks/useStore';
import { IEmployeesSearchParams } from '../../types/employees.interface';
import { EUserRole } from '../../types/user.interface';
import { AppMenuItem } from '../core/contextMenu';
import { ArrowDown } from '../core/icons/arrowDown';
import SearchIcon from '../core/icons/search';
import { EmployeesExport } from '../employeesExport';

export const StyledSearchInput = styled(Input)({
  borderBottom: '1px solid',
  borderBottomColor: '#7C79A1',
  color: '#fff',
  paddingBottom: 8,
  cursor: 'pointer',
  width: 171,
  fontSize: 13,
  lineHeight: 1.3,
  fontFamily: 'Proxima Nova Rg',
  fontWeight: 400,
  transition: 'all 200ms',
  '& input::placeholder': {
    color: '#fff',
    opacity: 1,
  },
  '&.search': {
    '& input::placeholder': {
      color: '#fff',
      opacity: 0.5,
    },
  },
  '&.Mui-focused': {
    borderBottomColor: '#fff',
  },
  '&.MuiInput-underline:after': {
    display: 'none',
  },
  '&.search-materials': {
    marginLeft: 8,
    paddingBottom: 0,
    paddingLeft: 5,
  },
});

const CustomSelect = styled(Select)({
  borderBottom: '1px solid',
  borderBottomColor: '#7C79A1',
  fontSize: 13,
  lineHeight: 1.3,
  fontFamily: 'Proxima Nova Rg',
  fontWeight: 400,
  color: '#fff',
  cursor: 'pointer',
  paddingBottom: 8,
  width: 171,
  transition: 'all 200ms',
  '&.Mui-focused': {
    borderBottomColor: '#fff',
  },
  '&.MuiInput-underline:after': {
    display: 'none',
  },
});

const useStyles = makeStyles(() => ({
  smallArrow: {
    fontSize: 12,
    marginRight: 11,
    marginLeft: 11,
    height: 20,
    color: '#fff',
    cursor: 'pointer',
    transition: 'transfrom 200ms',
  },
  pointerText: {
    cursor: 'pointer',
  },
}));

export interface EmployeesSearchBarProps {
  searchParams: IEmployeesSearchParams;
  onPartnerPick: (partnerId: string) => void;
  onRolePick: (status?: EUserRole) => void;
  onSearch: (value: string) => void;
  onToggleCreateUserForm: () => void;
}

export const EmployeesSearchBar: React.FC<EmployeesSearchBarProps> = ({
  searchParams,
  onPartnerPick,
  onRolePick,
  onSearch,
  onToggleCreateUserForm,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { profile } = useAppSelector((state) => state.user);
  const [showForm, setShowForm] = useState(false);
  const [searchFocus, setSearchFocus] = useState(false);
  const partnerSearchQuery = useQuery(['partners'], () => pAPI.list(0), {
    enabled: profile?.role === EUserRole.admin,
    useErrorBoundary: false,
    suspense: false,
  });

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    []
  );

  return (
    <Box mt={2} ml={2.5} p={2}>
      <Grid container item direction='row' justify='space-between'>
        <Grid container md item direction='row'>
          {profile?.role === EUserRole.admin && (
            <CustomSelect
              placeholder={t('words.partner')}
              variant='standard'
              value={searchParams.partner || 'all'}
              onChange={(e) => {
                if (e.target.value === 'all') {
                  onPartnerPick('');
                } else {
                  onPartnerPick(e.target.value as string);
                }
              }}
              renderValue={(value) => (
                <span>
                  {(partnerSearchQuery.data?.items || []).find(
                    (p) => p.id === value
                  )?.name || t('words.allPartners')}
                </span>
              )}
              // renderValue
              IconComponent={(props) => (
                <ArrowDown
                  onClick={props.onClick}
                  className={`${classes.smallArrow} MuiSelect-icon `}
                />
              )}
              MenuProps={{
                PaperProps: {
                  square: true,
                  style: {
                    maxHeight: 300,
                  },
                },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              <AppMenuItem value={'all'}>{t('words.allPartners')}</AppMenuItem>
              {!partnerSearchQuery.isError &&
                partnerSearchQuery.data?.items &&
                partnerSearchQuery.data?.items.map((i) => (
                  <AppMenuItem value={i.id} key={i.id}>
                    {i.name}
                  </AppMenuItem>
                ))}
            </CustomSelect>
          )}
          <Box ml={5} mr={5}>
            <CustomSelect
              placeholder={t('role.all')}
              variant='standard'
              value={searchParams.role || 'all'}
              onChange={(e) => {
                if (e.target.value === 'all') {
                  onRolePick();
                } else {
                  onRolePick(e.target.value as EUserRole);
                }
              }}
              IconComponent={(props) => (
                <ArrowDown
                  onClick={props.onClick}
                  className={`${classes.smallArrow} MuiSelect-icon `}
                />
              )}
              MenuProps={{
                PaperProps: {
                  square: true,
                },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              <AppMenuItem value={'all'}>{t('role.all')}</AppMenuItem>
              <AppMenuItem value={EUserRole.partner}>
                {t('role.partner')}
              </AppMenuItem>
              <AppMenuItem value={EUserRole.employee}>
                {t('role.employee')}
              </AppMenuItem>
              {profile && profile.role === EUserRole.admin ? (
                <AppMenuItem value={EUserRole.admin}>
                  {t('role.admin')}
                </AppMenuItem>
              ) : null}
              {profile && profile.role === EUserRole.admin ? (
                <AppMenuItem value={EUserRole.customer}>
                  {t('role.customer')}
                </AppMenuItem>
              ) : null}

              {profile && profile.role === EUserRole.admin ? (
                <AppMenuItem value={EUserRole.partner_admin}>
                  {t('role.partner_admin')}
                </AppMenuItem>
              ) : null}

              {profile && profile.role === EUserRole.admin ? (
                <AppMenuItem value={EUserRole.documentation_admin}>
                  {t('role.documentation_admin')}
                </AppMenuItem>
              ) : null}

              {profile && profile.role === EUserRole.admin ? (
                <AppMenuItem value={EUserRole.end_customer}>
                  {t('role.end_customer')}
                </AppMenuItem>
              ) : null}

              {profile && profile.role === EUserRole.admin ? (
                <AppMenuItem value={EUserRole.internal_team}>
                  {t('role.internal_team')}
                </AppMenuItem>
              ) : null}
            </CustomSelect>
          </Box>
          <StyledSearchInput
            style={{
              width: searchFocus ? 289 : 171,
            }}
            className='search'
            onChange={debouncedChangeHandler}
            onFocus={() => setSearchFocus(true)}
            onBlur={() => setSearchFocus(false)}
            placeholder={t('words.search')}
            endAdornment={<SearchIcon />}
          />
        </Grid>

        <div style={{ paddingRight: "5px", display: "flex", alignItems: "center" }}>
          <EmployeesExport searchParams={searchParams} />
        </div>

        {(profile && (profile.role === EUserRole.partner || profile.role === EUserRole.admin)) &&
          <AppWhiteButton
            onClick={() => {
              setShowForm(!showForm);
              onToggleCreateUserForm();
            }}
          >
            {t('component.employeeSearchbar.addEmployee')}
          </AppWhiteButton>
        }
      </Grid>
    </Box>
  );
};

export default EmployeesSearchBar;
