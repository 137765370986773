import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
  makeStyles,
  Box,
  Tooltip,
  Popover,
  Checkbox,
  styled,
  Select,
  MenuItem,
  Modal,
} from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp, Check } from '@material-ui/icons';
import React, { useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import NumberFormat from 'react-number-format';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import deleteApi from '../../api/delete';
import restoreApi from '../../api/restore';
import pApi from '../../api/partner';

import IPartner from '../../types/partner.interface';
import { formatPhoneNumber } from '../../utils/etc';
import AppButton from '../core/buttons';
import Archive from '../core/icons/archive';
import RestoreIcon from '../core/icons/restore';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { EProjectStatus, statusDict } from '../../types/project.interface';
import FieldArrowDown from '../core/icons/fieldArrowDown';
import { default as cAPI } from '../../api/city';
import PenIcon from '../core/icons/pen';
import AppInputLabel from '../core/label';
import FormItemRow from '../forms/formRow';
import ICity from '../../types/city.interface';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 6,
  p: 4,
};

const useStyles = makeStyles((theme) => ({
  sortSelector: {
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    // cursor: 'pointer',
    justifyContent: 'flex-end',
    textTransform: 'capitalize',
  },
  sortSelectorLeft: {
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  checkbox: {
    paddingLeft: 0,
    paddingRight: 0,
    padding: 0,
    marginRight: 18,
    stroke: theme.palette.primary.main,
    color: theme.palette.primary.main,
    '&:hover': {
      background: 'transparent',
    },
  },
  partnersTable: {
    flexWrap: 'nowrap',
    flexDirection: 'column',
    padding: 32,
    [theme.breakpoints.down('sm')]: {
      padding: 16,
    },
    '& td': {
      verticalAlign: 'top',
      padding: 0,
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 16,
    },
    '& thead': {
      borderTop: '1px solid rgba(224, 224, 224, 1)',
      '& th': {
        background: '#fff',
        color: '#909090',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        padding: 0,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 16,
      },
      '& span': {
        color: '#909090',
      },
      '& .stats': {
        '& span': {
          paddingRight: 16,
        },
      },
    },
    '& .name': {
      minWidth: 192,
      '& > a': {
        fontFamily: 'Proxima Nova Rg',
        fontSize: 16,
        lineHeight: 1.5,
        fontWeight: 400,
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '& > span': {
          background: '#00B852',
          padding: '1px 4px',
          color: '#fff',
          borderRadius: 3,
          fontSize: 13,
          lineHeight: 1.2,
          marginLeft: 8,
        },
      },
    },
    '& .stats': {
      minWidth: 159,
      '& p': {
        padding: 0,
        paddingRight: 16,
      },
    },
    '& .contact': {
      minWidth: 408,
    },
    '& .control': {
      paddingRight: 16,
      verticalAlign: 'middle',
    },
  },
  rootPadding: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
}));

const StyledSelect = styled(Select)({
  flex: 1,
  alignItems: 'center',
  borderRadius: 3,
  height: 40,
  '& svg': {
    right: 16,
    top: 'calc(50% - 6px)',
  },
  '& .MuiSelect-root': {
    padding: '8px 16px',
    paddingRight: 32,
  },
  '& fieldset': {
    borderColor: '#E8E8E8',
  },
  '& .MuiSelect-select': {
    border: '1px solid #e8e8e8',
    borderRadius: 3,
  },
});

const CustomMenuItem = styled(MenuItem)({
  '&.Mui-selected': {
    background: '#fff',
  },
});

const PartnerListItem: React.FC<{
  partner: IPartner;
  isArchived?: boolean;
  review?: boolean;
  onVerifyOpen?: (partner: IPartner) => void;
}> = ({ partner, isArchived, review, onVerifyOpen }) => {
  const [archived, setArchived] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { t } = useTranslation();

  const archive = useMutation('partners', deleteApi.partner, {
    onSuccess: () => {
      setArchived(true);
      enqueueSnackbar(t('page.partners.partnerSentToArchive'), {
        variant: 'info',
      });
    },
  });
  const restore = useMutation('partners', restoreApi.partner, {
    onSuccess: () => {
      setArchived(true);
      enqueueSnackbar(t('page.partners.partnerRestored'), { variant: 'info' });
    },
  });
  const handleArchive = async () => {
    archive.mutate(partner.id);
  };
  const handleRestore = async () => {
    restore.mutate(partner.id);
  };

  if (archived) return null;

  return (
    <TableRow>
      <TableCell className='logo'>
        {partner.logoId ? (
          <img
            src={`/api/v1/file/resolve/${partner.logoId}/none`}
            alt='logo'
            style={{
              height: 32,
              width: 32,
              borderRadius: 22,
              objectFit: 'fill',
            }}
          />
        ) : (
          <div
            style={{
              width: 32,
              height: 32,
              background: '#eee',
              borderRadius: 22,
            }}
          />
        )}
      </TableCell>
      <TableCell className='name'>
        {isArchived ? (
          <>
            {partner.name} {partner.isNew && <span>NEW</span>}
          </>
        ) : (
          <Link to={`/partner/${partner.id}`}>
            {partner.name} {partner.isNew && <span>NEW</span>}
          </Link>
        )}
      </TableCell>
      {review && <TableCell>
        {partnerCreatedAt(partner?.createdAt)}
      </TableCell>}
      <TableCell className='stats'>
        {partner.stats && (
          <Grid item container direction='row' justify='flex-end'>
            <Typography variant='body1'>
              <NumberFormat
                displayType='text'
                value={partner.stats.pending.amount}
                thousandSeparator=' '
                isNumericString
              />{' '}
              <span>{t('money.currencySign')}</span>
            </Typography>
            <Typography variant='body1'>
              {partner.stats.pending.number}
            </Typography>
          </Grid>
        )}
      </TableCell>
      <TableCell className='stats'>
        {partner.stats && (
          <Grid item container direction='row' justify='flex-end'>
            <Typography variant='body1'>
              <NumberFormat
                displayType='text'
                value={partner.stats.active.amount}
                thousandSeparator=' '
                isNumericString
              />{' '}
              <span>{t('money.currencySign')}</span>
            </Typography>
            <Typography variant='body1'>
              {partner.stats.active.number}
            </Typography>
          </Grid>
        )}
      </TableCell>
      <TableCell className='stats'>
        {partner.stats && (
          <Grid item container direction='row' justify='flex-end'>
            <Typography variant='body1'>
              <NumberFormat
                displayType='text'
                value={partner.stats.expired.amount}
                thousandSeparator=' '
                isNumericString
              />{' '}
              <span>{t('money.currencySign')}</span>
            </Typography>
            <Typography variant='body1'>
              {partner.stats.expired.number}
            </Typography>
          </Grid>
        )}
      </TableCell>
      <TableCell className='contact'>
        <Grid item container direction='column'>
          <Grid item>
            <Typography>{partner.fullName}</Typography>
          </Grid>
          <Grid item container direction='row'>
            <Box mr={1}>
              <Typography component='a' href={`mailto:${partner.email}`}>
                {partner.email}
              </Typography>
            </Box>
            <Typography>{formatPhoneNumber(partner.workPhone)}</Typography>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell width='24' className='control'>
        <Box display='flex' flexDirection='row' alignItems='center'>
          {isArchived ? (
            <AppButton
              startIcon={<RestoreIcon color='primary' />}
              onClick={handleRestore}
              color='primary'
            >
              {t('page.partners.restorePartner')}
            </AppButton>
          ) : (
            <Tooltip
              placement='top'
              title={t('page.partners.sendPartnerToArchive').toString()}
            >
              <span>
                <Archive
                  style={{ cursor: 'pointer' }}
                  onClick={handleArchive}
                />
              </span>
            </Tooltip>
          )}
          {review && (
            <Tooltip
              placement='top'
              title={t('page.partners.acceptTheRequest').toString()}
            >
              <Box ml={1}>
                <Check
                  onClick={() => onVerifyOpen && onVerifyOpen(partner)}
                  style={{ color: '#00B852', cursor: 'pointer' }}
                />
              </Box>
            </Tooltip>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
};

interface PartenrsListProps {
  hasMore: boolean;
  sortParams: { by: string; direction: 'asc' | 'desc' };
  onUpdateSortParams: (o: { by: string; direction: 'asc' | 'desc' }) => void;
  fetchNext: () => void;
  items: Array<IPartner>;
  archive?: boolean;
  review?: boolean;
}

export const PartnersList: React.FC<PartenrsListProps> = ({
  hasMore,
  fetchNext,
  onUpdateSortParams,
  items,
  archive,
  review,
  sortParams,
}) => {
  const classes = useStyles();
  const ref = useRef<HTMLElement>(null);
  const { t } = useTranslation();
  const qc = useQueryClient();

  const handleSortClick = (by: string) => {
    if (sortParams) {
      if (sortParams.by === by) {
        onUpdateSortParams({
          by,
          direction: sortParams.direction === 'asc' ? 'desc' : 'asc',
        });
      } else {
        onUpdateSortParams({ by, direction: 'asc' });
      }
    } else {
      onUpdateSortParams({ by, direction: 'asc' });
    }
  };

  const [openModal, setOpenModal] = React.useState(false);

  const citySearchQuery = useQuery(['cities'], () => cAPI.list(0), {
    useErrorBoundary: false,
    suspense: false,
  });

  const [selectedCountries, setSelectedCountries] = useState<ICity[]>([]);
  const [activePartner, setActivePartner] = useState<string | undefined>(
    undefined
  );

  const verify = useMutation('partners', pApi.verify, {
    onSuccess: () => {
      qc.invalidateQueries('partners');
    },
  });

  return (
    <Grid container item className={classes.partnersTable}>
      <TableContainer className='container'>
        <InfiniteScroll
          height='85vh'
          scrollableTarget={ref?.current}
          next={fetchNext}
          loader={null}
          dataLength={items.length}
          hasMore={hasMore}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align='left' className='logo'>
                  -
                </TableCell>
                <TableCell align='left' className='name'>
                  <Typography
                    className={classes.sortSelectorLeft}
                    variant='subtitle1'
                    onClick={() => handleSortClick('e.name')}
                  >
                    {t('words.partner')}
                    {sortParams?.by === 'e.name' ? (
                      sortParams?.direction === 'asc' ? (
                        <ArrowDropUp />
                      ) : (
                        <ArrowDropDown />
                      )
                    ) : null}
                  </Typography>
                </TableCell>
                {review && <TableCell>
                  {t('page.partnerProfile.registrationDate')}
                </TableCell>}
                <TableCell align='right' className='stats'>
                  <Typography
                    className={classes.sortSelector}
                    variant='caption'
                    // onClick={() => handleSortClick('count_with_pending')}
                  >
                    {statusDict[EProjectStatus.pending]}
                    {/* {sortParams?.by === 'count_with_pending' ? (
                      sortParams?.direction === 'asc' ? (
                        <ArrowDropUp />
                      ) : (
                        <ArrowDropDown />
                      )
                    ) : null} */}
                  </Typography>
                </TableCell>
                <TableCell align='right' className='stats'>
                  <Typography
                    className={classes.sortSelector}
                    variant='caption'
                    // onClick={() => handleSortClick('count_with_active')}
                  >
                    {statusDict[EProjectStatus.active]}
                    {/* {sortParams?.by === 'count_with_active' ? (
                      sortParams?.direction === 'asc' ? (
                        <ArrowDropUp />
                      ) : (
                        <ArrowDropDown />
                      )
                    ) : null} */}
                  </Typography>
                </TableCell>
                <TableCell align='right' className='stats'>
                  <Typography
                    className={classes.sortSelector}
                    variant='caption'
                    // onClick={() => handleSortClick('count_with_cancelled')}
                  >
                    {statusDict[EProjectStatus.expired]}

                    {/* {sortParams?.by === 'count_with_cancelled' ? (
                      sortParams?.direction === 'asc' ? (
                        <ArrowDropUp />
                      ) : (
                        <ArrowDropDown />
                      )
                    ) : null} */}
                  </Typography>
                </TableCell>
                <TableCell align='left' className='contact'>
                  <Typography variant='caption' align='right'>
                    {t('page.partners.partnerContract')}
                  </Typography>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody innerRef={ref}>
              {items.map((partner) => (
                <PartnerListItem
                  review={review}
                  isArchived={archive}
                  partner={partner}
                  key={partner.id}
                  onVerifyOpen={(partner) => {
                    setActivePartner(partner.id);
                    setOpenModal(true);
                  }}
                />
              ))}
            </TableBody>
          </Table>
        </InfiniteScroll>
      </TableContainer>

      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setSelectedCountries([]);
        }}
        disableEnforceFocus={true}
        disableAutoFocus={true}
      >
        <Box sx={style}>
          <FormItemRow>
            <AppInputLabel>{t('words.countries')}</AppInputLabel>
            <StyledSelect
              multiple
              value={selectedCountries}
              disableUnderline={true}
              style={{ width: '100%' }}
              IconComponent={(props) => <FieldArrowDown {...props} />}
              renderValue={(selected: any) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gridGap: 1,
                  }}
                >
                  {selected.map((value: any) => (
                    <span
                      style={{
                        display: 'inline-block',
                        background: '#1C00A0',
                        color: '#fff',
                        borderRadius: '33px',
                        padding: '4px 15px',
                        fontSize: '14px',
                      }}
                    >
                      {value?.name}
                    </span>
                  ))}
                </Box>
              )}
              MenuProps={{
                PaperProps: {
                  square: true,
                  style: {
                    maxHeight: 300,
                  },
                },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              {!citySearchQuery.isError &&
                citySearchQuery.data?.items &&
                citySearchQuery.data?.items.map((i) => (
                  <CustomMenuItem
                    value={i.id}
                    key={i.id}
                    onClick={() => {
                      if (selectedCountries.some((v) => v.id === i.id)) {
                        setSelectedCountries(
                          selectedCountries.filter((c) => c.id !== i.id)
                        );
                        return;
                      }

                      setSelectedCountries([...selectedCountries, i]);
                    }}
                  >
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr min-content',
                        width: '100%',
                      }}
                    >
                      <span>{i.name}</span>
                      <Checkbox
                        className={classes.checkbox}
                        color='primary'
                        checked={selectedCountries.some((v) => v.id === i.id)}
                      />
                    </div>
                  </CustomMenuItem>
                ))}
            </StyledSelect>
          </FormItemRow>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <AppButton
              style={{ padding: '6px 20px', height: '38px' }}
              size='medium'
              color='primary'
              variant='contained'
              disabled={!selectedCountries.length}
              onClick={() => {
                if (!activePartner) return;

                setSelectedCountries([]);
                setOpenModal(false);
                verify.mutate({
                  id: activePartner,
                  countries: selectedCountries,
                });
              }}
            >
              {t('words.accept')}
            </AppButton>
          </div>
        </Box>
      </Modal>
    </Grid>
  );
};

export default PartnersList;

function partnerCreatedAt(data: any) {
  if (typeof data != 'object') {
    return "";
  }
  if (!Boolean(data?.date)) {
    return "";
  }

  try {
    const date = new Date(data?.date);
    const addLeadingZero = (num: any) => num < 10 ? `0${num}` : num;
    const day = addLeadingZero(date.getDate());
    const month = addLeadingZero(date.getMonth() + 1);
    const year = date.getFullYear();
    const hours = addLeadingZero(date.getHours());
    const minutes = addLeadingZero(date.getMinutes());
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  } catch (e) {
    return "---";
  }
}
