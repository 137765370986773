import { makeStyles, styled } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import config, { ServiceType } from '../../app.config';
import useRbac from '../../hooks/useRbac';
import { useAppSelector } from '../../hooks/useStore';
import { EUserRole } from '../../types/user.interface';
import { AppContextMenu, AppMenuItem, AppPopover } from '../core/contextMenu';
import { UnionIcon } from '../core/icons/union';

const NavWrap = styled('nav')({
  height: 56,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
});

const StyledUnionIcon = styled(UnionIcon)({
  width: 10,
  height: 10,
});

const AppNavMenuButton = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  height: 56,
  display: 'flex',
  justifyContent: 'flex-end',
  flexDirection: 'column',
  textDecoration: 'none',
  marginRight: 24,
  fontFamily: 'Proxima Nova Lt',
  cursor: 'pointer',
  '&:before': {
    content: '""',
    top: 0,
    display: 'none',
    width: '100%',
    height: 4,
    backgroundColor: theme.palette.common.white,
  },
  '&:hover': {
    '&:before': {
      display: 'flex',
      backgroundColor: theme.palette.primary.light,
    },
  },
  '&.active': {
    '&:before': {
      display: 'flex',
      backgroundColor: theme.palette.common.white,
    },
  },
  '& > span': {
    height: 52,
    display: 'flex',
    alignItems: 'flex-end',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '20px',
  },
}));

const AppNavLink = styled(NavLink)(({ theme }) => ({
  color: theme.palette.common.white,
  height: 56,
  display: 'flex',
  justifyContent: 'flex-end',
  flexDirection: 'column',
  textDecoration: 'none',
  fontFamily: 'Proxima Nova Lt',
  marginRight: 24,
  '&:before': {
    content: '""',
    top: 0,
    display: 'none',
    width: '100%',
    height: 4,
    backgroundColor: theme.palette.common.white,
  },
  '&:hover': {
    '&:before': {
      display: 'flex',
      backgroundColor: theme.palette.primary.light,
    },
  },
  '&.active': {
    '&:before': {
      display: 'flex',
      backgroundColor: theme.palette.common.white,
    },
  },
  '& > span': {
    height: 52,
    display: 'flex',
    alignItems: 'flex-end',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '20px',
  },
}));

const useStyles = makeStyles((theme) => ({
  blankLinkIcon: {
    bottom: '5px',
    position: 'relative',
    left: '5px',
  },
  blankLink: {
    color: theme.palette.common.white,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    fontSize: '13px',
    textDecoration: 'none',
    fontFamily: 'Proxima Nova Lt',
    marginRight: 24,
    '&:before': {
      content: '""',
      top: 0,
      display: 'none',
      width: '100%',
      height: 4,
      backgroundColor: theme.palette.common.white,
    },
    '&.active': {
      '&:before': {
        display: 'flex',
        backgroundColor: theme.palette.common.white,
      },
    },
    '& > span': {
      height: 52,
      display: 'flex',
      alignItems: 'flex-end',
      fontWeight: 600,
      fontSize: '13px',
      lineHeight: '20px',
    },
  },
}));

interface ProductLink {
  title: string;
  link: string;
}

export const AppNav: React.FC = () => {
  const [prodAnchorEl, setProdAnchorEl] = React.useState<HTMLElement | null>(
    null
  );
  const [materialAnchorEl, setMaterialAnchor] =
    React.useState<SVGElement | null>(null);
  const { profile } = useAppSelector((state) => state.user);
  const handleClick = (event: React.SyntheticEvent<HTMLSpanElement>) => {
    setProdAnchorEl(event.currentTarget);
  };
  const { t } = useTranslation();
  const rbac = useRbac();
  const classes = useStyles();

  const handleClose = () => {
    setProdAnchorEl(null);
  };
  return (
    <NavWrap>
      {rbac.can('project.list') && (
        <>
          <AppNavLink to='/' exact>
            <span>{t('words.projects')}</span>
          </AppNavLink>
        </>
      )}
      {rbac.can('partner.list') && (
        <AppNavLink to='/partners'>
          <span>{t('header.partners')}</span>
        </AppNavLink>
      )}
      {rbac.can('employee.list') && (
        <>
          <AppNavLink to='/employees'>
            <span>{t('header.employees')}</span>
          </AppNavLink>
        </>
      )}
      {profile && profile?.role !== EUserRole.customer && (
        <>
          <AppNavLink to='/materials'>
            <span>{t('header.materials')}</span>
          </AppNavLink>
        </>
      )}

      {config.service === ServiceType.Spitch && <AppNavLink to='/documentation'>
        <span>{t('header.docs')}</span>
      </AppNavLink>}

      {config.service === ServiceType.Spitch && <AppNavLink to='/software'>
        <span>{t('header.softs')}</span>
      </AppNavLink>}

      {config.service === ServiceType.Bss && <AppNavLink to='/education'>
        <span>{t('header.education')}</span>
      </AppNavLink>}

      {profile && profile?.role !== EUserRole.customer && (
        <>
          {rbac.can('country.list') && (
            <AppNavLink to='/countries'>
              <span>{t('header.countries')}</span>
            </AppNavLink>
          )}
          <AppPopover
            keepMounted
            anchorEl={materialAnchorEl}
            open={Boolean(materialAnchorEl)}
            onClose={() => setMaterialAnchor(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <AppContextMenu>
              <AppMenuItem>
                <Link to='/materials/sales'>{t('material.type.sales')}</Link>
              </AppMenuItem>
              <AppMenuItem>
                <Link to='/materials/tech'>{t('material.type.tech')}=</Link>
              </AppMenuItem>
              <AppMenuItem>
                <Link to='/materials/ads'>{t('material.type.ads')}</Link>
              </AppMenuItem>
              <AppMenuItem>
                <Link to='/materials/partnership'>
                  {t('material.type.partnership')}
                </Link>
              </AppMenuItem>
              <AppMenuItem>
                <Link to='/materials/ads'>{t('material.type.cases')}</Link>
              </AppMenuItem>
            </AppContextMenu>
          </AppPopover>
          {rbac.can('myPartner.view') && (
            <AppNavLink to='/company-profile'>
              <span>{t('header.companyProfile')}</span>
            </AppNavLink>
          )}
        </>
      )}

      <AppNavMenuButton onClick={handleClick}>
        <span>
          {t('header.products')}{' '}
          <KeyboardArrowDown
            style={{
              width: '20px',
              marginLeft: 3,
              height: 'auto',
              transition: 'all 200ms',
              transform: prodAnchorEl ? 'rotate(180deg)' : undefined,
            }}
          />
        </span>
      </AppNavMenuButton>

      <AppPopover
        keepMounted
        anchorEl={prodAnchorEl}
        open={Boolean(prodAnchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <AppContextMenu>
          {(
            t('header.productsLinks', { returnObjects: true }) as ProductLink[]
          ).map((item) => (
            <AppMenuItem>
              <a href={item.link} rel='noreferrer' target='_blank'>
                {item.title} <StyledUnionIcon width={'10px'} />
              </a>
            </AppMenuItem>
          ))}
        </AppContextMenu>
      </AppPopover>

      {config.service === ServiceType.Spitch ? (
        <a
          href='https://spitch.ai/news/'
          target='_blank'
          className={classes.blankLink}
          rel='noreferrer'
        >
          <span>
            {t('header.news')}
            <StyledUnionIcon className={classes.blankLinkIcon} width={'10px'} />
          </span>
        </a>
      ) : (
        <a
          href='https://bssys.com/about/press-center/'
          target='_blank'
          className={classes.blankLink}
          style={{ display: "inline-block" }}
          rel='noreferrer'
        >
          <span>
            {t('header.news')}
            <StyledUnionIcon className={classes.blankLinkIcon} width={'10px'} />
          </span>
        </a>
      )}

      {config.service === ServiceType.Bss && (
        <a
          href='https://bssys.com/about/press-center/events/'
          target='_blank'
          className={classes.blankLink}
          style={{ display: "inline-block" }}
          rel='noreferrer'
        >
          <span>
            {t('header.events')}
            <StyledUnionIcon className={classes.blankLinkIcon} width={'10px'} />
          </span>
        </a>
      )}
    </NavWrap>
  );
};
