import {
  Box,
  Collapse,
  Grid,
  IconButton,
  makeStyles,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { default as projApi } from '../../api/project';
import { ArrowDropDown, ArrowDropUp, MoreVert } from '@material-ui/icons';
import React, { useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import NumberFormat from 'react-number-format';
import { useMutation } from 'react-query';
import IProject, {
  EProjectStatus,
  IProjectsSearchParams,
  statusDict,
} from '../../types/project.interface';
import { dateFormatter, timeFormatter } from '../../utils/dateTimeFormatter';
import AppButton from '../core/buttons';
import {
  AppContextMenu,
  AppMenuItem as AMI,
  AppPopover,
} from '../core/contextMenu';
import { ArrowDown } from '../core/icons/arrowDown';
import MoneyInput from '../inputs/moneyInput';
import ProjectCard from '../projectCard';
import { qc } from '../../App';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../hooks/useStore';
import { EUserRole } from '../../types/user.interface';

const AppMenuItem = styled(AMI)(({ theme }) => ({
  minWidth: 167,
  padding: '4px 16px',
  fontSize: 16,
  lineHeight: 1.5,
  fontWeight: 400,
  display: 'flex',
  flexDirection: 'column',
  '& .status': {
    padding: '8px 16px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      '& .fat-dot': {
        background: theme.palette.common.white,
      },
    },
  },
  '& .shorter': {
    width: 107,
  },
  '& .fat-dot': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    background: 'black',
  },
  '&:hover': {
    background: 'white',
    color: 'inherit',
  },
}));

const useStyles = makeStyles((theme) => ({
  tableHead: {
    paddingTop: 8,
    paddingBottom: 8,
    borderTop: '1px solid',
  },
  rootPadding: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  clickableCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    flexWrap: 'nowrap',
    '& svg': {
      fontSize: 16,
    },
  },
  projectsGridTable: {
    flexWrap: 'nowrap',
    flexDirection: 'column',
    '& .container': {
      // overflowX: 'hidden',
      // height: '80vh',
    },
    '& td': {
      verticalAlign: 'top',
      padding: 0,
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 16,
    },
    '& thead': {
      borderTop: '1px solid #909090',
      '& th': {
        background: '#fff',
        zIndex: 1,
        color: '#909090',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        padding: 0,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 16,
      },
    },
    '& .statusCell': {
      minWidth: 104,
      paddingLeft: 0,
      '&.head': {
        paddingLeft: theme.spacing(2),
      },
    },

    '& .statusInitialCell': {
      minWidth: 170,
      paddingLeft: 0,
      '&.head': {
        paddingLeft: theme.spacing(2),
      },
    },
    '& .partnerCell': {
      minWidth: 126,
    },
    '& .customerCell': {
      minWidth: 236,
    },
    '& .createdCell': {
      minWidth: 163,
    },
    '& .subjectCell': {
      minWidth: 179,
    },
    '& .dealCell': {
      minWidth: 122,
    },
    '& .priceCell': {
      minWidth: 150,
    },
  },
  [EProjectStatus.active]: {
    color: '#65ae31',
  },
  [EProjectStatus.pending]: {
    color: '#65ae31',
  },
  [EProjectStatus.expired]: {
    color: '#ff954c',
  },
  [EProjectStatus.closed]: {
    color: '#6b6b6b',
  },
  [EProjectStatus.inProcess]: {
    color: '#6b6b6b',
  },
  paper: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      maxHeight: '100%',
      maxWidth: '100%',
      top: '0!importnat',
      left: '0!importnat',
      overflow: 'scroll',
    },
  },
}));

const StyledMoreVertButton = styled(IconButton)({
  padding: 0,
  '&:hover': {
    color: '#7891F8',
  },
});

const StatusTypogrpahy = styled(Typography)({
  display: 'inline-flex',
  alignItems: 'center',
  cursor: 'pointer',
  flexDirection: 'row',
  paddingLeft: 16,
});
interface PorjectListItemProps {
  project: IProject;
  isAdmin: boolean;
  onStatusUpdate: (status: EProjectStatus, amount?: number) => void;
  isStatusUpdatable: boolean;
}

async function updateProjectStatus(
  project: IProject,
  status: EProjectStatus,
  comment: string,
  actualAmount?: number
): Promise<IProject> {
  const response = await projApi.update({
    ...project,
    files: project.files.map((f) => f.id),
    partner: project.partner?.id,
    city: project.city?.id,
    manager: project.manager?.id,
    actualAmount: actualAmount ? actualAmount : project.actualAmount,
    status: status,
    comment: comment,
    dealAt: undefined,
  });
  return response;
}

const ProjectListItem: React.FC<PorjectListItemProps> = ({
  project,
  onStatusUpdate,
  isAdmin,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const [cardAnchor, setCardAnchor] = useState<HTMLElement | null>(null);
  const [showSellForm, setShowSellForm] = useState(false);
  const [actualAmount, setActualAmount] = useState(
    project.actualAmount?.toString() || ''
  );
  const user = useAppSelector((state) => state.user);
  const isMine = user?.partner?.id && user?.partner?.id === project?.partner?.id;

  return (
    <TableRow className={classes.tableHead}>
      <TableCell className='statusInitialCell'>
        <StatusTypogrpahy
          onClick={(e) => {
            if (!isAdmin && !isMine) return;
            setAnchor(e.currentTarget);
          }}
          className={classes[project.status as keyof typeof classes]}
          variant='body1'
          style={{
            ...((isAdmin || isMine)
              ? {
                  cursor: 'pointer',
                }
              : {
                  cursor: 'default',
                }),
          }}
        >
          <span>{statusDict[project.status]}</span>

          {(isAdmin || isMine) && (
            <ArrowDown
              style={{
                fontSize: 12,
                marginLeft: 8,
                transition: 'transform 200ms',
                transform: !!anchor ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          )}
        </StatusTypogrpahy>
        <AppPopover
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          anchorEl={anchor}
          open={Boolean(anchor)}
          onClose={() => setAnchor(null)}
        >
          <AppContextMenu style={{ width: 170 }}>
            {Object.values(EProjectStatus).map((curStatus) => (
              <AppMenuItem
                value={EProjectStatus[curStatus]}
                className={
                  classes[EProjectStatus[curStatus] as keyof typeof classes]
                }
                disabled={!isAdmin && !isMine}
              >
                <div
                  className='status'
                  onClick={() => {
                    if (curStatus === EProjectStatus.pending) {
                      setShowSellForm((s) => !s);
                      return;
                    }
                    onStatusUpdate(EProjectStatus[curStatus]);
                    setAnchor(null);
                  }}
                >
                  <span>{t(`projectStatus.${curStatus}`)}</span>{' '}
                  {project.status === curStatus && <span className='fat-dot' />}
                </div>

                {curStatus === EProjectStatus.pending && (
                  <Collapse in={showSellForm} style={{ width: '100%' }}>
                    <Box my={1}>
                      <Typography variant='subtitle1'>
                        {t('page.projects.actualAmount')}
                      </Typography>
                      <Box mt={1} />
                      <MoneyInput
                        value={actualAmount}
                        onChange={(e) => setActualAmount(e.target.value)}
                        className='shorter'
                      />
                      <Box mt={1} />
                      <AppButton
                        className='shorter'
                        variant='outlined'
                        color='primary'
                        onClick={() => {
                          onStatusUpdate(EProjectStatus.pending, +actualAmount);
                          setAnchor(null);
                        }}
                      >
                        {' '}
                        {t('button.add')}{' '}
                      </AppButton>
                    </Box>
                  </Collapse>
                )}
              </AppMenuItem>
            ))}
          </AppContextMenu>
        </AppPopover>
      </TableCell>

      <TableCell>
        <Typography variant='body1'>
          <Link
            style={{ textDecoration: 'none', color: 'inherit' }}
            to={`/partner/${project.partner?.id}`}
          >
            {project.partner?.name}
          </Link>
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1'>{project.customer}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1'>{project.city?.name ?? '-'}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1'>
          {dateFormatter.format(new Date(+project.createdAt * 1000))} -{' '}
          {timeFormatter.format(new Date(+project.createdAt * 1000))}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1'>{project.subject}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1'>
          {project.dealAt &&
            dateFormatter.format(new Date(+project.dealAt * 1000))}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1'>
          {project.expireAt &&
            dateFormatter.format(new Date(+project.expireAt * 1000))}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1'>
          <NumberFormat
            displayType='text'
            value={
              project.actualAmount
                ? project.actualAmount
                : project.requestedAmount
            }
            thousandSeparator=' '
            isNumericString
          />
        </Typography>
      </TableCell>
      <TableCell>
        <small>{project.commentFree || ""}</small>
      </TableCell>
      <TableCell>
        <StyledMoreVertButton
          color='default'
          onClick={(e) => setCardAnchor(e.currentTarget)}
        >
          <MoreVert />
        </StyledMoreVertButton>
        <AppPopover
          anchorEl={cardAnchor}
          open={Boolean(cardAnchor)}
          onClose={() => setCardAnchor(null)}
          paperClassName={classes.paper}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          marginThreshold={0}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
        >
          <ProjectCard project={project} onClose={() => setCardAnchor(null)} />
        </AppPopover>
      </TableCell>
    </TableRow>
  );
};

interface ProjectsListProps {
  items: Array<IProject>;
  hasMore: boolean;
  fetchNext: () => void;
  onFilterDirectionClick: (name: string, direction: 'asc' | 'desc') => void;
  searchParams: IProjectsSearchParams;
}

export const ProjectsList: React.FC<ProjectsListProps> = ({
  items,
  hasMore,
  fetchNext,
  onFilterDirectionClick,
  searchParams,
}) => {
  const classes = useStyles();
  const ref = useRef<HTMLElement | null>(null);
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user);
  const statusUpdate = useMutation(
    ['projects', searchParams],
    ({
      project,
      status,
      comment,
      actualAmount,
    }: {
      project: IProject;
      status: EProjectStatus;
      comment: string;
      actualAmount?: number;
    }) => updateProjectStatus(project, status, comment, actualAmount),
    {
      onSuccess: (data) => {
        qc.invalidateQueries(['projects', searchParams]);
      },
    }
  );

  const handleSortClick = (name: string) => {
    if (searchParams && searchParams.sort) {
      if (searchParams.sort.by === name) {
        onFilterDirectionClick(
          name,
          searchParams.sort.direction === 'asc' ? 'desc' : 'asc'
        );
      } else {
        onFilterDirectionClick(name, 'asc');
      }
    } else {
      onFilterDirectionClick(name, 'asc');
    }
  };

  return (
    <Grid container className={classes.projectsGridTable}>
      <Box className={classes.rootPadding}>
        <TableContainer>
          <InfiniteScroll
            height='75vh'
            hasMore={hasMore}
            next={fetchNext}
            loader={null}
            dataLength={items.length}
            scrollableTarget={ref.current}
          >
            <Table stickyHeader className='container'>
              <TableHead>
                <TableRow>
                  <TableCell className='statusCell head'>
                    <Typography variant='subtitle1'>
                      {t('page.projects.tableStatus')}
                    </Typography>
                  </TableCell>
                  <TableCell className='partnerCell'>
                    <Typography variant='subtitle1'>
                      {t('page.projects.tablePartner')}
                    </Typography>
                  </TableCell>
                  <TableCell className='customerCell'>
                    <Typography
                      className={classes.clickableCell}
                      variant='subtitle1'
                      onClick={() => handleSortClick('customer')}
                    >
                      {t('page.projects.tableCustomer')}
                      {searchParams?.sort?.by === 'customer' ? (
                        searchParams?.sort?.direction === 'asc' ? (
                          <ArrowDropDown />
                        ) : (
                          <ArrowDropUp />
                        )
                      ) : (
                        <ArrowDropDown />
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell className='cityCell'>
                    <Typography variant='subtitle1'>
                      {t('page.projects.tableCity')}
                    </Typography>
                  </TableCell>
                  <TableCell className='createdCell'>
                    <Typography
                      className={classes.clickableCell}
                      variant='subtitle1'
                      onClick={() => handleSortClick('createdAt')}
                    >
                      {t('page.projects.tableCreatedAt')}
                      {searchParams?.sort?.by === 'createdAt' ? (
                        searchParams?.sort?.direction === 'asc' ? (
                          <ArrowDropDown />
                        ) : (
                          <ArrowDropUp />
                        )
                      ) : (
                        <ArrowDropDown />
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell className='statusCell'>
                    <Typography variant='subtitle1'>
                      {t('page.projects.tableSubject')}
                    </Typography>
                  </TableCell>
                  <TableCell className='dealCell'>
                    <Typography
                      className={classes.clickableCell}
                      variant='subtitle1'
                      onClick={() => handleSortClick('dealAt')}
                    >
                      {t('page.projects.tableDealAt')}
                      {searchParams?.sort?.by === 'dealAt' ? (
                        searchParams?.sort?.direction === 'asc' ? (
                          <ArrowDropDown />
                        ) : (
                          <ArrowDropUp />
                        )
                      ) : (
                        <ArrowDropDown />
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell className='expireCell'>
                    <Typography
                      className={classes.clickableCell}
                      variant='subtitle1'
                      onClick={() => handleSortClick('expireAt')}
                    >
                      {t('page.projects.expireAt')}
                      {searchParams?.sort?.by === 'expireAt' ? (
                        searchParams?.sort?.direction === 'asc' ? (
                          <ArrowDropDown />
                        ) : (
                          <ArrowDropUp />
                        )
                      ) : (
                        <ArrowDropDown />
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell className='priceCell'>
                    <Typography
                      className={classes.clickableCell}
                      variant='subtitle1'
                      onClick={() => handleSortClick('actualAmount')}
                    >
                      {t('page.projects.tableAmount')}
                      {searchParams?.sort?.by === 'actualAmount' ? (
                        searchParams?.sort?.direction === 'asc' ? (
                          <ArrowDropDown />
                        ) : (
                          <ArrowDropUp />
                        )
                      ) : (
                        <ArrowDropDown />
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell className='commentCell'>
                    <Typography
                      className={classes.clickableCell}
                      variant='subtitle1'
                    >
                      {t('page.projects.tableComment')}
                    </Typography>
                  </TableCell>
                  <TableCell width='40'></TableCell>
                </TableRow>
              </TableHead>
              <TableBody innerRef={ref}>
                {items.map((project) => (
                  <ProjectListItem
                    onStatusUpdate={async (status, actualAmount) => {
                      const reason = // @ts-ignore
                      (
                        prompt(t('page.projectEdit.statusChangeReasonField')) ??
                        ''
                      ).trim();

                      if (reason === '') return;

                      statusUpdate.mutate({
                        project,
                        status,
                        comment: reason,
                        actualAmount,
                      });
                    }}
                    isStatusUpdatable={user?.profile?.role === EUserRole.admin}
                    key={project.id}
                    project={project}
                    isAdmin={user.profile?.role === EUserRole.admin}
                  />
                ))}
              </TableBody>
            </Table>
          </InfiniteScroll>
        </TableContainer>
      </Box>
    </Grid>
  );
};

export default ProjectsList;
