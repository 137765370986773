import { makeStyles, styled } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useState } from 'react';
import http from '../../api/client';
import { IProjectsSearchParams } from '../../types/project.interface';
import ExportFileIcon from '../core/icons/exportFile';
import { useTranslation } from 'react-i18next';

export const Btn = styled('button')(({ theme }) => ({
  border: `1px solid #7C79A2`,
  display: 'inline-flex',
  margin: 0,
  padding: 0,
  alignItems: 'center',
  background: 'transparent',
  borderRadius: 4,
  color: '#FFF',
  position: 'relative',
  width: 'auto',
  cursor: 'pointer',
  flex: 0,
  flexGrow: 0,
  '& svg': {
    borderRight: `1px solid #7C79A2`,
    height: '100%',
    margin: -1,
  },
  '& span': {
    padding: '0 16px',
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '18px',
    fontFamily: 'Proxima Nova Lt',
  },
}));

const useStyles = makeStyles({
  shimmer: {
    position: 'absolute',
    textAlign: 'center',
    color: 'rgba(255,255,255,0.1)',
    background:
      'gradient(linear, left top, right top, from(rgba(0,0,0,.1)), to(rgba(0,0,0,.1)), color-stop(0.5, #fff))',
    backgroundSize: '300px 100%',
    animationName: '$shimmer',
    animationDuration: '2s',
    animationIterationCount: 'infinite',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0 0',
    left: 0,
    opacity: 0.3,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  '@keyframes shimmer': {
    '0%': {
      backgroundPosition: 'top right',
    },
    '50%': {
      backgroundPosition: ' top left',
    },
    '100%': {
      backgroundPosition: ' top right',
    },
  },
});

export const ProjectsExport: React.FC<{
  searchParams: IProjectsSearchParams;
}> = ({ searchParams }) => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [id, setId] = useState<string | undefined>(undefined);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleClick = async () => {
    if (!isLoading && !id) {
      try {
        const res = await http.post('/data_export/project', {
          ...searchParams,
        });
        setId(res.data.data.id);
        setLoading(true);
        enqueueSnackbar(t('toastNotifications.weRBuildingTheSheet'), {
          variant: 'info',
        });
        handleTask(res.data.data.id);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleTask = async (id: string) => {
    try {
      const res = await http.post('/data_export/task', { id });
      if (!res.data.data.file) {
        setTimeout(() => handleTask(id), 500);
      } else {
        setLoading(false);
        enqueueSnackbar(t('toastNotifications.tableRReady'), {
          variant: 'info',
        });
        let link = document.createElement('a');
        link.setAttribute(
          'href',
          `/api/v1/file/resolve/${res.data.data.file.id}/${res.data.data.file.name}`
        );
        link.setAttribute('download', 'file');
        link.click();
        setId('');
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <Btn onClick={handleClick}>
      {isLoading && (
        <div
          className={`${classes.shimmer} ${classes['@keyframes shimmer']}`}
        ></div>
      )}
      <ExportFileIcon text={'XLS'} />
      <span>{t('words.projects')}</span>
    </Btn>
  );
};
