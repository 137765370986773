import { Box, Drawer, Typography, styled, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import IFile from '../../types/file.interface';
import AppButton from '../core/buttons';
import AppInputLabel from '../core/label';
import AppInput from '../core/outlinedInput';
import FormItemRow from '../forms/formRow';
import { useTranslation } from 'react-i18next';
import FieldArrowDown from '../core/icons/fieldArrowDown';
import { AppMenuItem, AppPopover } from '../core/contextMenu';
import docs from '../../api/docs';
import docsRelease from '../../api/docsRelease';
import softs from '../../api/softs';
import softsRelease from '../../api/softsRelease';
import IDocProduct from '../../types/docRelease.interface';
import { useHistory } from 'react-router';
import ReactCountryFlag from 'react-country-flag';
import LoadingFile from '../loadingFile';
import FileCard from '../fileCard';
import { FileDropZone } from '../fileDropZone';
import IDoc from '../../types/doc.interface';

const FatDot = styled('div')({
  width: 8,
  height: 8,
  borderRadius: '50%',
  background: 'black',
});

const SelectLike = styled(Box)(() => ({
  padding: '8px 16px',
  paddingRight: 6,
  height: 40,
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: '1px solid #E8E8E8',
  boxSizing: 'border-box',
  borderRadius: 4,
  cursor: 'pointer',
  position: 'relative',
  '& svg': {
    right: 16,
    top: 'calc(50% - 6px)',
    cursor: 'poniter',
    position: 'absolute',
  },
  '&:hover': {
    border: '1px solid #000',
  },
}));

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 13,
    minWidth: 65,
    lineHeight: '20px',
    flex: 0,
  },
  mi: {
    '& .selected': {
      fontWeight: 600,
    },
  },
  firstRow: {
    marginBottom: 16,
  },
  secondRow: {
    marginBottom: 0,
  },
  btn: {
    fontSize: 13,
    lineHeight: '20px',
    backgroundColor: '#fff',
  },
  exBtn: {
    padding: 0,
    paddingLeft: 16,
    paddingTop: 14,
    '& > svg': {
      fontSize: 8,
    },
    '& .MuiButton-startIcon': {
      marginRight: 4,
    },
    '&:hover': {
      background: 'transparent',
    },
  },
  saveBtn: {
    padding: '10px 24px',
    marginTop: theme.spacing(3),
  },
}));

interface MaterialFileDrawerProps {
  updateTarget: IDoc;
  onClose: () => void;
  isSoftware?: boolean;
}

export const MaterialEditDocumentDrawer: React.FC<MaterialFileDrawerProps> = ({
  onClose,
  updateTarget,
  isSoftware,
}) => {
  const classes = useStyles();
  const [title, setTitle] = useState<string>(updateTarget.title);
  const [url, setUrl] = useState<string>(updateTarget.url);
  const [description, setDescription] = useState<string>(
    updateTarget.description
  );
  const [pdfUrl, setPdfUrl] = useState<string>(updateTarget?.pdfUrl ?? '');

  const [picture, setPicture] = useState<IFile | undefined>(
    updateTarget.picture
  );
  const qc = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [newLang, setNewLang] = useState<string>(updateTarget.lang as string);
  const [release, setRelease] = useState<IDocProduct>(updateTarget.release);
  const [files, setFiles] = useState<Array<File>>([]);
  const history = useHistory();

  const [releaseAnchor, setReleaseAnchor] = useState<HTMLElement | null>(null);
  const [langAnchor, setLangAnchor] = useState<HTMLElement | null>(null);

  const repositoryRelease = isSoftware ? softsRelease : docsRelease;
  const repository = isSoftware ? softs : docs;
  const [repoKey, setRepoKey] = useState<any>(isSoftware ? "software" : "docs");
  const [urlPart, setUrlPart] = useState<String>(isSoftware ? "software" : "documentation");

  const releasesQuery = useQuery(`${repoKey}-releases`, repositoryRelease.list, {
    suspense: false,
  });

  const docEdit = useMutation(repoKey, repository.edit, {
    onSuccess: () => {
      enqueueSnackbar(t('toastNotifications.docEdited'), {
        variant: 'info',
      });
      qc.invalidateQueries([repoKey, release!.id]);
      qc.invalidateQueries([repoKey, undefined]);
      setPicture(undefined);
      onClose();
      history.push(`/${urlPart}/${release!.id}`);
    },
    onError: (err) => {
      //@ts-ignore
      enqueueSnackbar((err as any).response.data.errors[0].reason, {
        variant: 'error',
      });
    },
  });

  const handleEditDocument = () => {
    docEdit.mutate({
      id: updateTarget.id,
      release: release!,
      url,
      title,
      picture,
      pdfUrl,
      lang: newLang as any,
      description,
    });
  };

  const handleLoadSuccess = (fileResponse: IFile, f: File) => {
    const nf = files.filter((file) => file.name !== f.name);
    setFiles(nf);
    setPicture(fileResponse);
  };

  const handleFileDelete = () => {
    setPicture(undefined);
  };

  const handleNewFiles = async (list: FileList) => {
    for (let i = 0; i < list.length; i++) {
      setFiles((files) => [...files, list[i]]);
    }
  };

  useEffect(() => {
    isSoftware && setUrl("http://localhost");
  }, [isSoftware]);

  const isFormFilled = useMemo(
    () =>
      ![title, url, release, newLang].some((v) => v === '' || v === undefined),
    [newLang, release, title, url]
  );

  return (
    <Drawer
      onBackdropClick={onClose}
      onClose={onClose}
      open={true}
      anchor='right'
      PaperProps={{
        style: { boxShadow: '0px 4px 20px rgba(15, 4, 139, 0.11)', width: 487 },
      }}
      ModalProps={{
        hideBackdrop: false,
        BackdropProps: {
          invisible: true,
        },
      }}
    >
      <Box mx={3} mt={3}>
        <Box mb={2}>
          <Typography variant='h3'>
            {t('component.addDocumentDrawer.editHeadline')}
          </Typography>
        </Box>

        <Box>
          {files.map((f, i) => (
            <LoadingFile
              onLoadSuccess={(fileResponse) =>
                handleLoadSuccess(fileResponse, f)
              }
              key={f.name + i}
              file={f}
            />
          ))}
          {picture ? (
            <FileCard
              key={picture.id}
              file={picture}
              onDelete={handleFileDelete}
            />
          ) : (
            <Box height={284}>
              <FileDropZone
                onChange={handleNewFiles}
                allowedFileExtensions={[]}
                allowedMimeTypes={[]}
              />
            </Box>
          )}
        </Box>

        <Box display='flex' flexDirection='column' mt={3}>
          <FormItemRow>
            <AppInputLabel>
              {t('component.addDocumentDrawer.title')}*
            </AppInputLabel>

            <AppInput
              defaultValue={updateTarget.title}
              onChange={(v) => setTitle(v.target.value)}
            />
          </FormItemRow>

          <FormItemRow>
            <AppInputLabel>
              {t('component.addDocumentDrawer.description')}
            </AppInputLabel>

            <AppInput
              multiline
              defaultValue={updateTarget.description}
              onChange={(v) => setDescription(v.target.value)}
            />
          </FormItemRow>

          {!isSoftware && <>
            <FormItemRow>
              <AppInputLabel>
                {t('component.addDocumentDrawer.url')}*
              </AppInputLabel>

              <AppInput
                defaultValue={updateTarget.url}
                onChange={(v) => setUrl(v.target.value)}
              />
            </FormItemRow>

            <FormItemRow>
              <AppInputLabel>
                {t('component.addDocumentDrawer.pdfUrl')}
              </AppInputLabel>

              <AppInput
                defaultValue={updateTarget.pdfUrl ?? ''}
                onChange={(v) => setPdfUrl(v.target.value)}
              />
            </FormItemRow>
          </>}

          <FormItemRow>
            <AppInputLabel>
              {t('component.addDocumentDrawer.lang')}*
            </AppInputLabel>

            <SelectLike onClick={(e) => setLangAnchor(e.currentTarget)}>
              <Typography>
                {newLang !== '' ? t(`langs.${newLang}`) : ''}
              </Typography>
              <FieldArrowDown />
            </SelectLike>

            <AppPopover
              anchorEl={langAnchor}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              open={!!langAnchor}
              onBackdropClick={() => setLangAnchor(null)}
              onClose={() => setLangAnchor(null)}
              PaperProps={{
                style: {
                  minWidth: 380,
                  height: 110,
                  maxHeight: 280,
                },
              }}
            >
              {['gb', 'ru', 'de'].map((countryCode) => (
                <AppMenuItem
                  className={classes.mi}
                  key={countryCode}
                  value={countryCode}
                  onClick={() => {
                    setLangAnchor(null);
                    setNewLang(countryCode);
                  }}
                >
                  <Box
                    display='flex'
                    flexDirection='row'
                    alignItems='center'
                    flexWrap='nowrap'
                    width='100%'
                    ml='0px'
                    mr='0px'
                  >
                    <ReactCountryFlag
                      countryCode={countryCode}
                      svg
                      style={{
                        width: '12px',
                        height: '12px',
                      }}
                    />
                    <Box display='flex' ml='10px' flexDirection='column'>
                      <Typography variant='body1'>
                        {t(`langs.${countryCode}`)}
                      </Typography>
                    </Box>
                  </Box>
                </AppMenuItem>
              ))}
            </AppPopover>
          </FormItemRow>

          <FormItemRow>
            <AppInputLabel>
              {t('component.addDocumentDrawer.release')}*
            </AppInputLabel>

            <SelectLike onClick={(e) => setReleaseAnchor(e.currentTarget)}>
              <Typography>{release?.title}</Typography>
              <FieldArrowDown />
            </SelectLike>

            <AppPopover
              anchorEl={releaseAnchor}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              open={!!releaseAnchor}
              onBackdropClick={() => setReleaseAnchor(null)}
              onClose={() => setReleaseAnchor(null)}
              PaperProps={{
                style: {
                  minWidth: 380,
                  height: 230,
                  maxHeight: 440,
                },
              }}
            >
              <Box>
                {releasesQuery.data &&
                  releasesQuery.data.map((item) => (
                    <AppMenuItem
                      className={classes.mi}
                      key={item.id}
                      value={item.id}
                      onClick={() => {
                        setReleaseAnchor(null);
                        setRelease(item);
                      }}
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='space-between'
                        alignItems='center'
                        flexWrap='nowrap'
                        width='100%'
                        ml='0px'
                        mr='0px'
                      >
                        <Box display='flex' flexDirection='column'>
                          <Typography variant='body1'>{item.title}</Typography>
                        </Box>
                        {release?.id === item.id && <FatDot />}
                      </Box>
                    </AppMenuItem>
                  ))}
              </Box>
            </AppPopover>
          </FormItemRow>

          <Box mt={1}>
            <FormItemRow>
              <AppInputLabel />

              <AppButton
                disabled={!isFormFilled}
                variant='contained'
                color='primary'
                onClick={handleEditDocument}
              >
                {t('button.save')}
              </AppButton>
            </FormItemRow>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};
