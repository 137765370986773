import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import docs from '../../api/softs';
import AppButton, { AppWhiteButton } from '../../components/core/buttons';
import { useTranslation } from 'react-i18next';
import { MaterialDocumentDrawer } from '../../components/materialDocumentDrawer';
import CloseIcon from '../../components/core/icons/close';
import IDoc from '../../types/doc.interface';
import { dateFormatter, timeFormatter } from '../../utils/dateTimeFormatter';
import deleteApi from '../../api/delete';
import { qc } from '../../App';
import { EUserRole } from '../../types/user.interface';
import { useAppSelector } from '../../hooks/useStore';
import StyledNavLink from '../../components/core/styledNavLink';
import { useHistory, useParams } from 'react-router';
import docsRelease from '../../api/softsRelease';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { MaterialProductDrawer } from '../../components/materialProductDrawer';
import { MaterialEditProductDrawer } from '../../components/materialEditProductDrawer';
import IDocProduct from '../../types/docRelease.interface';
import { MaterialEditDocumentDrawer } from '../../components/materialEditDocumentDrawer';
import Download from '../../components/core/icons/download';
import { Opacity } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  productHeadBanner: {
    width: '100%',
    height: '20vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundColor: '#eee',
    position: 'relative',
  },
  rtableRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 220px 200px', //'1fr 220px 150px 200px',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    background: 'white',

    '& .row-column': {
      padding: '12px 16px',
    },
    '& span.row-column': {
      color: '#909090',
    },

    '& .link-download': {
      cursor: 'pointer',
      opacity: 0.6,
      color: 'black',
      '&:hover': {
        opacity: 1,
      },
    },

    '& .link': {
      color: 'blue',
      cursor: 'pointer',

      '&:hover': {
        opacity: 0.8,
      },
    },
  },

  rtableNotFound: {
    textAlign: 'center',
    color: '#3c3c3c',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    padding: '10px',
  },

  root: {
    display: 'block',
    height: 'calc(100vh - 149px)',
  },
  rootPaddingWrapper: {
    marginTop: '68px',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  rootPadding: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(3.5),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      // marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  createDocumentButton: {
    margin: '15px 0',
  },

  partnersTableWrapper: {
    padding: 32,
    paddingTop: 0,
  },

  listRoot: {
    backgroundColor: theme.palette.common.white,
    minHeight: 'calc(100vh - 157px)',
    display: 'block',
  },
  navRoot: {},
  whiteLinkBtn: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 12,
      paddingRight: 12,
    },
  },

  listItemImage: {
    width: 'auto',
    height: '65px',
    marginRight: '15px',
  },

  currentDocumentClose: {
    color: '#fff',
    cursor: 'pointer',
  },

  currentDocumentFrame: {
    border: 'none',
    width: '100%',
    background: 'white',
    zIndex: 3,
    right: '0px',
    height: 'calc(100vh - 162px)',
  },
}));

const arrayMove = (array: IDoc[], oldIndex: number, newIndex: number) => {
  const copy = [...array];
  const valueToMove = copy.splice(oldIndex, 1)[0];
  copy.splice(newIndex, 0, valueToMove);
  return copy;
};

export const SoftwareMainPage: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [sortedDocs, setSortedDocs] = useState<IDoc[]>([]);
  const [showCreateReleaseDrawer, setShowCreateReleaseDrawer] = useState(false);
  const [showCreateProductDrawer, setShowCreateProductDrawer] = useState(false);

  const [updateProduct, setUpdateProduct] = useState<IDocProduct | undefined>();
  const [updateDoc, setUpdateDoc] = useState<IDoc | undefined>();

  const [currentDoc, setCurrentDoc] = useState<string | null>(null);
  const params = useParams<{ id?: string }>();
  const history = useHistory();
  const user = useAppSelector((state) => state.user);

  const isEditable = user?.profile
    && (
      user.profile.role === EUserRole.admin
      || user.profile.role === EUserRole.documentation_admin
    );

  const docsQuery = useQuery(
    ['software', params?.id],
    () => docs.list(params?.id),
    {
      suspense: false,
    }
  );

  const releasesQuery = useQuery('software-releases', docsRelease.list, {
    suspense: false,
  });

  useEffect(() => {
    const [firstRelease] = releasesQuery?.data ?? [];
    if (!firstRelease || params!.id !== undefined) return;

    history.push(`/software/${firstRelease.id}`);
  }, [releasesQuery, params?.id]);

  const currentProduct = useMemo(() => {
    if (!params?.id) return;
    return releasesQuery?.data?.find((v) => v.id === params?.id);
  }, [params?.id, releasesQuery?.data]);

  useMemo(() => {
    // todo have to be refactored later
    let docsToBeSorted: IDoc[] = docsQuery.data ?? [];
    let result: IDoc[] = [];

    currentProduct?.sortings?.forEach((id) => {
      const targetIndex = docsToBeSorted.findIndex((v) => v?.id === id);
      if (!docsToBeSorted[targetIndex]) {
        return;
      }

      result.push(docsToBeSorted[targetIndex]);
      docsToBeSorted = docsToBeSorted.filter(
        (_, index) => index !== targetIndex
      );
    });

    setSortedDocs([...result, ...docsToBeSorted]);
  }, [currentProduct, docsQuery.data]);

  const docsDelete = useMutation('software', deleteApi.soft, {
    onSuccess: () => qc.invalidateQueries('software'),
  });

  const docsSort = useMutation('software', docsRelease.sort, {});

  const handlerOpenDocument = (doc: IDoc) => {
    setCurrentDoc(doc.url);
  };

  const handlerCloseDocument = () => {
    setCurrentDoc(null);
  };

  const handleDragResult = (e: DropResult) => {
    if (e.destination?.index === undefined || e.source.index === undefined) {
      return;
    }
    const result = arrayMove(sortedDocs, e.source.index, e.destination.index);

    docsSort.mutate({
      target: currentProduct!,
      payload: result.map((v) => v.id),
    }, {
      onSuccess: () =>
        releasesQuery.refetch(),
    });
    setSortedDocs(result);
  };

  return (
    <>
      <Grid
        style={{ width: '100%' }}
        container
        item
        direction='column'
        className={classes.root}
      >
        <Box className={classes.rootPadding}>
          <Grid
            item
            container
            justifyContent='space-between'
            style={{
              paddingBottom:
                currentDoc || currentProduct?.picture ? '0px' : '8px',
            }}
          >
            <Box display='flex' flexDirection='row' alignItems='flex-end'>
              {(releasesQuery.data ?? []).map((v) => (
                <StyledNavLink
                  to={`/software/${v.id}`}
                  onClick={() => setCurrentDoc(null)}
                  key={v.id}
                  exact
                >
                  <Typography variant='h5'>{v.title}</Typography>
                </StyledNavLink>
              ))}
            </Box>
            <Box mb={2} alignItems='center' display='flex'>
              {currentDoc ? (
                <CloseIcon
                  className={classes.currentDocumentClose}
                  onClick={() => handlerCloseDocument()}
                  strokeColor='white'
                  style={{ marginTop: '8px' }}
                />
              ) : user?.profile?.role === EUserRole.admin ? (
                <AppWhiteButton
                  style={{ textTransform: 'none' }}
                  onClick={() => setShowCreateProductDrawer(true)}
                >
                  {t('page.software.addProduct')}
                </AppWhiteButton>
              ) : null}
            </Box>
          </Grid>
        </Box>

        {/* {!currentDoc && currentProduct?.picture && (
          <div
            className={classes.productHeadBanner}
            style={{
              backgroundImage: `url(/api/v1/file/resolve/${currentProduct?.picture?.id}/none)`,
            }}
          />
        )} */}

        <Grid item container direction='column' className={classes.listRoot}>
          {currentDoc && (
            <div>
              <iframe
                title='iframe'
                src={currentDoc}
                className={classes.currentDocumentFrame}
              />
            </div>
          )}

          {!currentDoc && currentProduct && (
            <Grid
              container
              spacing={2}
              style={{ maxWidth: '100%' }}
              justifyContent='center'
            >
              <Grid md={8} xs={12} lg={8}>
                {isEditable && (
                  <Grid container justifyContent='space-between'>
                    <AppButton
                      variant='outlined'
                      color='secondary'
                      onClick={() => setUpdateProduct(currentProduct!)}
                      fullWidth={false}
                      className={classes.createDocumentButton}
                    >
                      {t('page.software.editProduct')}
                    </AppButton>

                    <AppButton
                      variant='contained'
                      color='secondary'
                      onClick={() => setShowCreateReleaseDrawer(true)}
                      fullWidth={false}
                      className={classes.createDocumentButton}
                    >
                      {t('page.software.addDoc')}
                    </AppButton>
                  </Grid>
                )}

                <div>
                  <div
                    className={classes.rtableRow}
                    style={{ borderTop: 'none' }}
                  >
                    <span className='row-column'>
                      <Typography variant='subtitle1'>
                        {t('words.title')}
                      </Typography>
                    </span>
                    <span className='row-column'></span>


                    <span className='row-column'>
                      <Typography variant='subtitle1' align='right'>
                        {t('page.software.createdAt')}
                      </Typography>
                    </span>
                  </div>

                  <DragDropContext onDragEnd={handleDragResult}>
                    <Droppable
                      droppableId='droppable'
                      isDropDisabled={!isEditable}
                    >
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {sortedDocs.map((doc, index) => (
                            <Draggable
                              key={doc.id}
                              draggableId={doc.id}
                              index={index}
                              isDragDisabled={
                                !isEditable
                              }
                            >
                              {(provided) => (
                                <div
                                  className={classes.rtableRow}
                                  ref={provided.innerRef}
                                  key={`row-${doc.id}`}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={provided.draggableProps.style}
                                >
                                  <div className='row-column'>
                                    <Typography variant='subtitle1'>
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        {doc.picture && <a
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginRight: '15px',
                                            cursor: 'pointer',
                                          }}
                                          className='link-download'
                                          title={doc.picture.name}
                                          href={`/api/v1/file/resolve/${doc.picture.id}/${doc.picture.name}`}
                                          rel='norefferer'
                                          target='_blank'
                                          download
                                        >
                                          <Download />
                                        </a>}

                                        {/* {doc.picture && (
                                          <img
                                            src={`/api/v1/file/resolve/${doc.picture.id}/none`}
                                            alt={doc.title}
                                            className={classes.listItemImage}
                                          />
                                        )} */}

                                        <div style={{ width: '100%' }}>
                                          <div
                                            className='title-text'
                                            onClick={() => {
                                              // handlerOpenDocument(doc)
                                            }}
                                          >
                                            <strong>{doc.title}</strong>
                                          </div>
                                          <div
                                            style={{
                                              whiteSpace: 'pre-line',
                                            }}
                                          >
                                            {doc.description}
                                          </div>
                                        </div>
                                      </div>
                                    </Typography>
                                  </div>
                                  <div className='row-column'>
                                    <Typography
                                      variant='subtitle1'
                                      align='right'
                                    >
                                      {doc.pdfUrl && (
                                        <a
                                          href={doc.pdfUrl}
                                          target='_blank'
                                          rel='noreferrer'
                                        >
                                          PDF
                                        </a>
                                      )}

                                      {user.profile &&
                                        isEditable && (
                                          <span
                                            style={{ marginLeft: '10px' }}
                                            className='link'
                                            onClick={() => setUpdateDoc(doc)}
                                          >
                                            {t('page.software.edit')}
                                          </span>
                                        )}

                                      {user.profile &&
                                        user.profile.role ===
                                        EUserRole.admin && (
                                          <span
                                            style={{ marginLeft: '10px' }}
                                            className='link'
                                            onClick={() => {
                                              if (
                                                !confirm(
                                                  t('page.software.deleteCheck')
                                                )
                                              ) {
                                                return;
                                              }

                                              docsDelete.mutate(doc.id);
                                            }}
                                          >
                                            {t('page.software.delete')}
                                          </span>
                                        )}
                                    </Typography>
                                  </div>
                                  {/*<div className='row-column'>*/}
                                  {/*  <Typography variant='subtitle1'>*/}
                                  {/*    <ReactCountryFlag*/}
                                  {/*      countryCode={doc.lang}*/}
                                  {/*      svg*/}
                                  {/*      style={{*/}
                                  {/*        width: '16px',*/}
                                  {/*        height: '16px',*/}
                                  {/*        paddingRight: '5px',*/}
                                  {/*      }}*/}
                                  {/*    />*/}
                                  {/*    <span>{t(`langs.${doc.lang}`)}</span>*/}
                                  {/*  </Typography>*/}
                                  {/*</div>*/}
                                  <div className='row-column'>
                                    <Typography
                                      variant='subtitle1'
                                      align='right'
                                    >
                                      {dateFormatter.format(
                                        new Date(+doc.createdAt * 1000)
                                      )}{' '}
                                      -{' '}
                                      {timeFormatter.format(
                                        new Date(+doc.createdAt * 1000)
                                      )}
                                    </Typography>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>

                  {!sortedDocs.length && (
                    <div className={classes.rtableNotFound}>
                      {t('page.software.noRowsFounds')}
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          )}
        </Grid>

        <MaterialDocumentDrawer
          open={showCreateReleaseDrawer}
          onClose={() => setShowCreateReleaseDrawer(false)}
          isSoftware={true}
        />

        <MaterialProductDrawer
          open={showCreateProductDrawer}
          onClose={() => setShowCreateProductDrawer(false)}
          isSoftware={true}
        />

        {updateProduct && (
          <MaterialEditProductDrawer
            onClose={() => setUpdateProduct(undefined)}
            updateTarget={updateProduct}
            isDelete={user.profile && user.profile.role === EUserRole.admin}
            isSoftware={true}
          />
        )}

        {updateDoc && (
          <MaterialEditDocumentDrawer
            onClose={() => setUpdateDoc(undefined)}
            updateTarget={updateDoc}
            isSoftware={true}
          />
        )}
      </Grid>
    </>
  );
};

export default SoftwareMainPage;
