import {
  Box,
  Divider,
  Grid,
  Hidden,
  makeStyles,
  styled,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import IProject, { statusDict } from '../../types/project.interface';
import { dateFormatter, timeFormatter } from '../../utils/dateTimeFormatter';
import { formatPhoneNumber } from '../../utils/etc';
import AppButton from '../core/buttons';
import CloseIcon from '../core/icons/close';
import FileCard from '../fileCard';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

const Status = styled(Box)(({ theme }) => ({
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: '2px',
  width: 'auto',
  maxWidth: 'auto',
  display: 'flex',
  flex: 0,
  alignSelf: 'flex-start',
  alignItems: 'center',
  '& > span': {
    letterSpacing: 2,
  },
  '&.active': {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
  },
  '&.pending': {
    color: theme.palette.common.black,
    borderColor: theme.palette.common.black,
  },
  '&.cancelled': {
    color: theme.palette.secondary.dark,
    borderColor: theme.palette.secondary.dark,
  },
  '& > *': {
    padding: '2px 16px',
  },
}));

const useStyles = makeStyles({
  greyText: {
    color: '#909090',
  },
  rowWithPadding: {
    paddingBottom: 8,
    alignItems: 'center',
  },
  rowWithExtraPadding: {
    paddingBottom: 8,
    paddingTop: 8,
    alignItems: 'center',
  },
  bold: {
    fontWeight: 600,
  },
  ebtn: {
    minHeight: 40,
  },
  rootBox: {
    width: 527,
    '@media (max-width: 1000px)': {
      width: 'auto',
      maxWidth: '100%',
      minHeight: '100vh',
    },
  },
});

const StyledClose = styled(CloseIcon)({
  position: 'absolute',
  right: 0,
  top: 0,
  padding: 16,
});

interface ProjectCardProps {
  project: IProject;
  onClose: () => void;
}

export const ProjectCard: React.FC<ProjectCardProps> = ({
  project,
  onClose,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.rootBox}>
      <Box p={3}>
        <StyledClose onClick={onClose} />
        <Grid container item direction='column'>
          <Grid item xs container alignItems='center'>
            <Status className={project.status}>
              <Typography variant='caption'>
                {statusDict[project.status].toUpperCase()}
              </Typography>
            </Status>
          </Grid>
          <Box mt={2} />
          <Typography variant='h4'>{project.subject}</Typography>
          <Box mt={1} />
          <Typography variant='caption'>{project.customer}</Typography>
          <Box mt={3} />
          <Grid item container direction='row' justify='space-between'>
            <Grid item md={7} sm={12} xs={12}>
              <Grid item container direction='column'>
                <Grid
                  item
                  container
                  className={classes.rowWithPadding}
                  direction='row'
                  justify='space-between'
                  alignItems='center'
                >
                  <Typography className={classes.greyText} variant='subtitle1'>
                    {t('component.projectCard.createdAt')}
                  </Typography>
                  <Typography variant='subtitle1'>
                    {timeFormatter.format(new Date(+project.createdAt * 1000))}{' '}
                    ·{' '}
                    {dateFormatter.format(new Date(+project.createdAt * 1000))}
                  </Typography>
                </Grid>
                {project.dealAt && (
                  <Grid
                    item
                    container
                    className={classes.rowWithExtraPadding}
                    direction='row'
                    justify='space-between'
                    alignItems='center'
                  >
                    <Typography
                      className={classes.greyText}
                      variant='subtitle1'
                    >
                      {t('component.projectCard.dealAt')}
                    </Typography>
                    <Typography variant='subtitle1'>
                      {dateFormatter.format(new Date(+project.dealAt * 1000))}
                    </Typography>
                  </Grid>
                )}
                {project.expireAt && (
                  <Grid
                    item
                    container
                    className={classes.rowWithExtraPadding}
                    direction='row'
                    justify='space-between'
                    alignItems='center'
                  >
                    <Typography
                      className={classes.greyText}
                      variant='subtitle1'
                    >
                      {t('page.projects.expireAt')}
                    </Typography>
                    <Typography variant='subtitle1'>
                      {dateFormatter.format(new Date(+project.expireAt * 1000))}
                    </Typography>
                  </Grid>
                )}
                <Grid
                  item
                  container
                  className={classes.rowWithPadding}
                  direction='row'
                  justify='space-between'
                  alignItems='center'
                >
                  <Typography className={classes.greyText} variant='subtitle1'>
                    {t('component.projectCard.department')}{' '}
                  </Typography>
                  <Typography variant='subtitle1'>
                    {project.department}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  className={classes.rowWithPadding}
                  direction='row'
                  justify='space-between'
                  alignItems='center'
                >
                  <Typography className={classes.greyText} variant='subtitle1'>
                    {t('component.projectCard.statusDescription')}{' '}
                  </Typography>
                  <Typography variant='subtitle1'>{project.comment}</Typography>
                </Grid>
                <Grid
                  item
                  container
                  className={classes.rowWithPadding}
                  direction='row'
                  justify='space-between'
                  alignItems='center'
                >
                  <Typography className={classes.greyText} variant='subtitle1'>
                    {t('component.projectCard.requestedAmount')}{' '}
                  </Typography>
                  <Typography variant='subtitle1'>
                    {project.requestedAmount}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  className={classes.rowWithPadding}
                  direction='row'
                  justify='space-between'
                  alignItems='center'
                >
                  <Typography className={classes.greyText} variant='subtitle1'>
                    {t('component.projectCard.actualAmount')}
                  </Typography>
                  <Typography variant='subtitle1'>
                    <NumberFormat
                      displayType='text'
                      value={project.actualAmount}
                      thousandSeparator=' '
                      isNumericString
                    />
                  </Typography>
                </Grid>
                {Boolean(project.commentFree) && (
                  <Grid
                    item
                    container
                    className={classes.rowWithPadding}
                    direction='row'
                    justify='space-between'
                    alignItems='center'
                  >
                    <Typography className={classes.greyText} variant='subtitle1'>
                      {t('component.projectCard.comment')}{' '}
                    </Typography>
                    <Typography variant='subtitle1'>{project.commentFree}</Typography>
                  </Grid>
              )}
              </Grid>
              <Box mt={1} mb={2}>
                <Divider />
              </Box>
              {project.partner && (
                <Grid item>
                  <Typography variant='subtitle1' className={classes.greyText}>
                    {t('component.projectCard.partner')}
                  </Typography>
                  <Box mb={0.5} />
                  <Typography variant='subtitle1' className={classes.bold}>
                    {project.partner.name}
                  </Typography>
                  <Box mb={0.5} />
                  <Typography variant='subtitle1'>
                    {project.partner.fullName}
                  </Typography>
                  <Box mb={0.5} />
                  <Typography variant='subtitle1'>
                    {formatPhoneNumber(project.partner.workPhone)} ·{' '}
                    <Typography
                      variant='subtitle1'
                      component='a'
                      href={`mailto:${project.partner.email}`}
                    >
                      {' '}
                      {project.partner.email}
                    </Typography>
                  </Typography>
                </Grid>
              )}

              {project.manager && (
                <Grid item>
                  <Box mt={2} />
                  <Typography variant='subtitle1' className={classes.greyText}>
                    {t('component.projectCard.contactPerson')}
                  </Typography>
                  <Box mb={0.5} />

                  <Typography variant='subtitle1'>
                    {project.manager?.fullName ?? 'Менеджер не назначен'}
                  </Typography>
                  <Box mb={0.5} />
                  <Typography variant='subtitle1'>
                    {project.manager?.phone
                      ? formatPhoneNumber(project.manager?.phone)
                      : 'Менеджер не назначен'}{' '}
                    ·{' '}
                    <Typography
                      variant='subtitle1'
                      component='a'
                      href={
                        project.manager?.email
                          ? `mailto:${project.manager?.email}`
                          : project.manager?.email
                      }
                    >
                      {' '}
                      {project.manager?.email ?? 'Менеджер не назначен'}
                    </Typography>
                  </Typography>

                  <Box mb={0.5} />
                </Grid>
              )}
              <Box mt={3.5} />
              <Hidden smDown>
                <AppButton variant='contained' color='primary' fullWidth>
                  <Link
                    style={{ color: 'inherit', textDecoration: 'none' }}
                    to={`/project/update/${project.id}`}
                  >
                    {t('button.editProject')}
                  </Link>
                </AppButton>
              </Hidden>
            </Grid>
            <Grid item md={4} sm={12} xs={12} container direction='column'>
              {project.files.map((file, index) => (
                <FileCard key={file.id} file={file} />
              ))}
            </Grid>
            <Hidden mdUp initialWidth='lg'>
              <Grid item>
                <Box mt={2} width='100%'>
                  <AppButton
                    variant='contained'
                    color='primary'
                    fullWidth
                    style={{ height: 40 }}
                  >
                    <Link
                      style={{ color: 'inherit', textDecoration: 'none' }}
                      to={`/project/update/${project.id}`}
                    >
                      {t('button.editProject')}
                    </Link>
                  </AppButton>
                </Box>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default ProjectCard;
